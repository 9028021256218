import * as React from 'react';
import PropTypes from 'prop-types';

const PhotoGrid = (props) => {
    const { data } = props;
    console.log('PHoto Grid', data)
    return (
        <div className="bg-yellow-100" style={{ backgroundColor: '#008f95' }}>
            <div className="md:px-4 pt-12 pb-12">
                <div className="container mx-auto font-sans pl-4 md:pl-4 lg:w-5/6 md:p-4">
                    <h3 className="dark:text-white text-3xl md:text-4xl font-bold pt-4 pb-8 ">Foto's</h3>
                    <div>
                        <div className="grid md:grid-cols-3 md:grid-cols-2 gap-8">
                        {data.photo.map((photoGridItem) => (
                            // console.log('item', photoGridItem)
                            <div className="pr-4 text-center">
                                <img className="inline" style={{'width': '300px'}} src={photoGridItem.file.url} />
                            </div>
                            ))}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default PhotoGrid;
